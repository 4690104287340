$(document).ready(() => {
  'use strict'
  /**
   * Menu Bahasa
   */
  const clickable = document.querySelectorAll('.drop-lang')

  const showMenu = (e) => {
    Array.from(clickable).forEach(drop => {
      drop.addEventListener('click',(e) => {
        drop.classList.toggle('active');
        e.stopPropagation();
      })
    })
  }
  const hideMenu = (e) => {
    Array.from(clickable).forEach(drop => {
      if(drop.classList.contains('active')) {
        drop.classList.remove('active')
      }
    })
    e.stopPropagation();
  }
  window.addEventListener('load', showMenu, false)
  window.addEventListener('click', hideMenu, false)
  /**
   * Open Menu In Mobile or Tablet
   */
  const menuWrapper = document.querySelector('.menu-primary')
  const btnMenu = document.querySelector('.btn-menu')

  const openMenu = (e) => {
    menuWrapper.classList.add('menu-primary--open')
    btnMenu.closest('.btn-menu-holder').style.zIndex = "-1"
    e.stopPropagation()
  }
  const closeMenu = (e) => {
    menuWrapper.classList.remove('menu-primary--open')
    btnMenu.closest('.btn-menu-holder').style.zIndex = "1"
    e.stopPropagation()
  }
  btnMenu.addEventListener('click', openMenu, false)
  window.addEventListener('click', closeMenu, false)
  /**
   * Sticky Header
   */
   const header = document.querySelector('.top-header');
   const logoDefault = document.getElementById('img--default')
   const logoDark = document.getElementById('img--dark')
   window.addEventListener('scroll', () => {
    let wh = this.scrollY
    if(wh > 0) {
      header.classList.add('top-header--sticky')
      logoDefault.classList.add('d-none')
      logoDark.classList.remove('d-none')
    } else {
      header.classList.remove('top-header--sticky')
      logoDefault.classList.remove('d-none')
      logoDark.classList.add('d-none')
    }
   }, false)
   /**
    * Counter
    */
  const section = document.querySelector('.statistic');
  let hasEntered = false;
  window.addEventListener('scroll', (e) => {
    let shouldAnimate = (window.scrollY + window.innerHeight) >= section.offsetTop;
    if (shouldAnimate && !hasEntered) {
      hasEntered = true;
      $('.number').each(function () {
        $(this).prop('Counter',0).animate({
          Counter: $(this).text()
        }, {
          duration: 5000,
          easing: 'swing',
          step: function (now) {
            $(this).text(Math.ceil(now));
          }
        });
      });
    }
  });
  /**
   * Modal Video
   */
  $(() => {
    const modalVideo = $('#modal-video')
    const companyModal = $('#company-modal')
    let url = companyModal.attr('src')
    modalVideo.on('show.bs.modal', () => {
      companyModal.attr('src', url+'?autoplay=1')
    })
    modalVideo.on('hide.bs.modal', () => {
      companyModal.attr('src', '')
    })
  })
  /**
   * Carousel Modul
   */
  $(() => {
    const sliderProduct = $('.carousel__product');
    sliderProduct.owlCarousel({
      items: 4,
      loop: false,
      margin: 30,
      nav: false,
      dots: false,
      responsive: {
        0:{
          items: 1,
          margin: 50
        },
        700: {
          items: 2,
        },
        1000: {
          items: 3,
          margin: 40
        },
        1200: {
          items: 4,
          margin: 30
        }
      }
    })

    $('.product-next').click(function() {
      sliderProduct.trigger('next.owl.carousel');
    })
    // Go to the previous item
    $('.product-prev').click(function() {
      // With optional speed parameter
      // Parameters has to be in square bracket '[]'
      sliderProduct.trigger('prev.owl.carousel', [300]);
    })
    //service carousel
    const sliderService = $('.carousel__service');
    sliderService.owlCarousel({
      items: 4,
      loop: false,
      margin: 30,
      nav: false,
      dots: false,
      responsive: {
        0:{
          items: 1,
          margin: 50
        },
        700: {
          items: 2,
        },
        1000: {
          items: 3,
          margin: 40
        },
        1200: {
          items: 4,
          margin: 30
        }
      }
    })

    $('.service-next').click(function() {
      sliderService.trigger('next.owl.carousel');
    })
    // Go to the previous item
    $('.service-prev').click(function() {
      // With optional speed parameter
      // Parameters has to be in square bracket '[]'
      sliderService.trigger('prev.owl.carousel', [300]);
    })
  })

  /**
   * Carousel Stories
   */
  var mySwiper = new Swiper('.swiper-container', {
    direction: 'vertical',
    slidesPerView: 2,
    spaceBetween: 70,
    mousewheel: true,
    navigation: {
      nextEl: '.custom-button-next',
      prevEl: '.custom-button-prev',
    },
    breakpoints: {
      0: {
        spaceBetween: 20
      },
      768: {
        spaceBetween: 30
      },
      1080: {
        spaceBetween: 50
      },
      1200: {
        spaceBetween: 70
      }
    },
    centeredSlides: true,
    observer: true,
    observeParents: true,
    observeSlideChildren: true
  });
  /**
   * Parallax Scroll
   */
})